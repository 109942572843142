import React from "react";
import AttentionGetter from "../components/AttentionGetter";
import Page from "../meta/Page";
import ServerStatusFrame from "../components/ServerStatusFrame/ServerStatusFrame";


function ServerStatus() {
    return (
        <Page title={"Status"}>
            <ServerStatusFrame></ServerStatusFrame>
        </Page>
    );
}

export default ServerStatus