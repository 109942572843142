import React, {CSSProperties, useEffect, useState} from "react";
import {getPluginInfo} from "../meta/data/ServerAPI";
import {Plugin} from "../meta/data/ServerAPI"
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import PluginCard from "./PluginCard";

const PluginPanel = () => {

    const [plugins,setPlugins] = useState<Plugin[]>();

    useEffect(() => {
        getPluginInfo().then((info) => {
            setPlugins(info);
            console.log(info);
        })
    },[]);

    if(plugins) {
        let pluginList = <></>;

        for (let i = 0; i < plugins.length; i++) {
            pluginList =  (
                <>
                    <>{pluginList}</>
                    <PluginCard plugin={plugins[i]}></PluginCard>
                </>
            );
        }


        return (
            <Box>
                {pluginList}
            </Box>
        )
    }

    return <p>Loading...</p>




}


export default PluginPanel