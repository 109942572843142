import React, {useEffect} from 'react';

import AttentionGetter from "../components/AttentionGetter";
import Page from "../meta/Page";
import {Box, Card, CardContent, CardHeader, CardMedia, Paper, Typography} from "@mui/material";
import Caden from "../assets/staff_images/caden.jpg"
import Wyatt from "../assets/staff_images/wyatt.jpg"
import Luke from "../assets/staff_images/luke.png"
import * as url from "url";
import StaffCard from "../components/StaffCard";


const About = () => {

    return (
        <Page title={"About"}>
            <Box sx={styles.root}>
                <Card sx={styles.card}>
                    <Typography variant={"h1"} color={"text"} sx={styles.text}>
                        Welcome To The FMSMP!
                    </Typography>
                    <Typography variant={"h2"} color={"secondary.light"} sx={styles.text}>
                        Est 2020
                    </Typography>

                    <Typography variant={"h5"} color={"primary.main"} sx={styles.text}>
                        The FMSMP was created out of a small Realm for our friends, and friends of friends, that outgrew the limited player counts of a Bedrock Realm. FM stems from the original server that contributed and played on the Realm, but has become a title fitting for everyone that came later. Rapidly changing from Realm to full blown, self hosted server, the FMSMP hopes to continue it's goal of creating a server for anyone and everyone.
                    </Typography>
                </Card>

                <Box sx={styles.staffContainer}>
                    <StaffCard
                        name={"Caden Crowder"}
                        roles={"Founder, Co-Creator"}
                        bio={"Began the server after wishing for a larger student body activity outside of sporting events meant for everyone. Continuing that idea through college and hopefully beyond by expanding the server's reach. Freshman at UIS, member of the Buttercup Quartet, main host of the FMSMP."}
                        img={Caden}

                    />
                    <StaffCard
                        name={"Wyatt Cole"}
                        roles={"Co-creator, Maintainer, and Graphic Designer"}
                        bio={"Underground base enthusiast. SIUC CompSci '27."}
                        img={Wyatt}

                    />
                    <StaffCard
                        name={"Luke Laurenzana"}
                        roles={"Developer, Web Designer"}
                        bio={"Permanent member of the mole kingdom. Loveless marrige with Java, current affair with Javascript. Illinois College Computer Science '26"}
                        img={Luke}

                    />
                </Box>
            </Box>
        </Page>
    );
}

const styles = {
    root: {
        display: "flex",
        width: "100vw",
        height: "95vh",

        justifyContent: "center",

        margin: "auto",

        flexDirection: "row",
        flexWrap: "wrap",
        overflowY: "auto",
    },

    card: {
        backgroundColor: "background.paper",
        padding: "10px",
        textAlign: "left",
        width: "95%"
    },
    text: {
        textShadow: "5px 3px 2px #000000",

        //color: "text.primary",
        //fontFamily: "Minecraft",
    },

    staffContainer: {
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "row",

        flexGrow: 1
    },

}

export default About;