import {Box, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React, {CSSProperties} from "react";
import {Player} from "../../meta/data/ServerAPI";

const PlayerList = (props: {players: Player[]}) => {
    let list = <></>;

    for (let i = 0; i < props.players.length; i++) {
        list =  (
            <>
                <>{list}</>
                <ListItem sx={{width:"100%"}} disablePadding>
                    <ListItemButton >
                        <ListItemIcon>
                            <img style={styles.img as CSSProperties} height={"40px"} src={"data:image/png;base64,"+props.players[i].icon} alt="icon"/>
                        </ListItemIcon>
                        <ListItemText primary={props.players[i].name}  />
                    </ListItemButton>
                </ListItem>
            </>
        );
    }

    return (
        <List >
            {list}
        </List>
    )
}

const styles = {
    img: {
        imageRendering: "pixelated"
    }

}


export default PlayerList