import React, {ReactNode, useEffect} from "react";
import NavigationBar from "../components/navigation/NavigationBar";
import {Box} from "@mui/material";


interface Props {
    title: string
    children: ReactNode,

}
const Page = (props: React.PropsWithChildren<Props>) => {
    useEffect(() => {
        document.title = props.title
    });
    return (
        <Box sx={{bgcolor: "background.default"}} style={{width: "100vw", height: "100vh"}}>
            <NavigationBar title={props.title}></NavigationBar>
            {[props.children]}
        </Box>
    )
}

export default Page