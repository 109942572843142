import {
    AppBar,
    Avatar,
    Box,
    Button, Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton, ListItemIcon, ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import React, {useState} from "react";

import MenuIcon from '@mui/icons-material/Menu';

import {useNavigate} from "react-router-dom";
import {NavSideBar} from "./NavSideBar";


interface navProps {
    title: string
}

const NavigationBar = (props: navProps) => {

    const [isHovered, setHovered] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const nav = useNavigate();
    const navigate = (link: string) => {
        nav(link)
        setDropdownOpen(false);
    }

    const styles = {
        root: {
            bgcolor: "background.paper",
            //backgroundColor: isHovered ? "#FFFFFF11" :"#00000011",

            //position: "absolute",
            display: "flex",
            flexDirection: "row",
            flexBasis: "center",
            justifyContent: "space-between",
        },
        background: {
            position: "absolute",
            width: "100%",
            height: "100%",
            //borderColor: "#FFFFFF",
            //backgroundColor: "#00000000",
            borderWidth: "2px",
            //backgroundImage: `url(${bg})`,
            backgroundPosition: 'center',

            backgroundRepeat: 'repeat'
        },
        background2: {
            position: "absolute",
            width: "100%",
            height: "100%",
            //backgroundColor: "#FFFFFF11"
        },


        toolbar: {

            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexBasis: "center",
            justifyContent: "space-between",
        },

        logo: {
            height: "100%",
            backgroundImage: `url()`,
            backgroundSize: "cover",
        },
        title: {

            textAlign: "center",
            fontFamily: "Minecraft",
            fontSize: 32,
            padding: 1
        },





    }


    return (
        <>
            <AppBar enableColorOnDark={true} sx={styles.root} position={"sticky"}
                    onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>

                <Toolbar  variant={"dense"} sx={styles.toolbar}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => {setDropdownOpen(true)}}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography sx={styles.title}>{props.title}</Typography>

                    <Avatar>

                    </Avatar>



                </Toolbar>
            </AppBar>
            <NavSideBar setOpen={setDropdownOpen} isOpen={isDropdownOpen}></NavSideBar>


        </>
    )


}


export default NavigationBar