import {Divider, Drawer, List, Typography} from "@mui/material";
import NavButton from "./NavButton";
import {Computer, Home, Info, Map, Power, PowerInput, QuestionMark} from "@mui/icons-material";
import React from "react";


export const NavSideBar = (props:{
    isOpen:boolean
    setOpen(val:boolean): void
    }) => {



    return (
        <Drawer
            elevation={4}
            anchor={"left"}
            open={props.isOpen}
            onClose={() => {props.setOpen(false)}}
            PaperProps={{
                sx: {
                    bgcolor: "background.paper",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "flex-start",


                    //backgroundColor: "#00000022",
                }

            }}

        >
            <List>
                <Typography variant={"h6"}sx={styles.sidebarSubtitle}>Web</Typography>
                <NavButton setFunction={props.setOpen} text="Home" link="/" icon={<Home></Home>}></NavButton>
                <NavButton setFunction={props.setOpen} text="About" link="/about" icon={<QuestionMark></QuestionMark>}></NavButton>

                <Divider></Divider>
                <Typography variant={"h6"} sx={styles.sidebarSubtitle}>Server</Typography>
                <NavButton setFunction={props.setOpen} text="Information" link="/info" icon={<Info></Info>}></NavButton>
                <NavButton setFunction={props.setOpen} text="Status" link="/status" icon={<Computer></Computer>}></NavButton>
                <NavButton setFunction={props.setOpen} text="Plugins" link="/plugins" icon={<Power></Power>}></NavButton>
                <NavButton setFunction={props.setOpen} text="Map" link="/map" icon={<Map></Map>}></NavButton>
            </List>

        </Drawer>
    )
}

const styles = {
    sidebarTitle: {
        //color: "#FFFFFF",
        textAlign: "center",

    },

    sidebarSubtitle: {
        //color: "#FFFFFF",
        textAlign: "center",

    },


    buttonClass: {
        display: "flex",
        flexDirection: "column",
        padding: "1px"
    },
}