import {Plugin} from "../meta/data/ServerAPI"
import React from "react";
import {Card, CardContent, CardHeader, Typography} from "@mui/material";
const PluginCard = (props: {plugin:Plugin}) => {
    return (
        <Card>
            <CardHeader title={props.plugin.name + " " + props.plugin.version} subheader={props.plugin.authors}></CardHeader>
            <CardContent>
                <Typography>{props.plugin.desc}</Typography>
            </CardContent>

        </Card>
    )
}

export default PluginCard