import React, {useEffect} from 'react';

import AttentionGetter from "../components/AttentionGetter";
import Page from "../meta/Page";



const Home = () => {

  return (
    <Page title={"Home"}>
        <AttentionGetter></AttentionGetter>
    </Page>
  );
}

export default Home;
