import {Box, duration, ImageList, ImageListItem, keyframes, Typography} from "@mui/material";
import React, {useEffect} from "react";
import "../index.css"

const AttentionGetter = () => {



    const containerRef = React.useRef<HTMLElement>(null);
    return (
        <Box sx={styles.root} ref={containerRef}>
            <style>
                {`
                @keyframes move {
                    0%   {left: -100%;}
                    100% { left: 100%;}
                }   
                `}
            </style>
            <ImageList  sx={styles.images1} cols={3} gap={0}>
                {itemData.map((item: any) => (
                    <ImageListItem key={item.img}>
                        <img
                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                            alt={"screenshot"}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <ImageList  sx={styles.images2} cols={3}  gap={0}>
                {itemData.map((item: any) => (
                    <ImageListItem key={item.img}>
                        <img
                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                            //alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <Box sx={styles.box}></Box>
            <Box sx={styles.text}>
                <Box sx={styles.titleContainer}>
                    <Typography sx={styles.title}>Welcome To The FMSMP</Typography>
                    <Typography sx={styles.subtitle}>What's it stand for? Stick around to find out...</Typography>
                </Box>
            </Box>


        </Box>
    )
}

const styles = {



    root: {

        width: "100vw",
        height: "100vh",
        overflow: "hidden"
    },

    box: {
        position: "absolute",
        width: "100%",
        height: "100%",
        bgcolor: "#00000099"
    },

    images1: {
        width: "100%",
        height: "100%",
        position: "absolute",
        animationName: "move",
        animationDuration: "50s",
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",


    },
    images2: {
        width: "100%",
        height: "100%",
        position: "absolute",
        animationName: "move",
        animationDuration: "50s",
        animationTimingFunction: "linear",
        animationDelay: "-25s",
        animationIterationCount: "infinite"

    },

    text: {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        textAlign: "center"
    },
    titleContainer: {
        marginTop: "35vh",
        //backgroundColor: "#00000033",
        padding: "3%",
        borderRadius: "5%",
        //borderColor: "#FFFFFF",
        //border: `2px solid #FFFFFF11`

    },

    title: {
        fontFamily: "Minecraft-Bold",
        color: "#FFFFFF",
        fontSize: 60,
        shadowColor: "#000000",
        textShadow: "2px 3px 2px #000000",


    },

    subtitle: {
        fontFamily: "Minecraft",
        color: "#FFFFFF",


    },

    textContainer: {
        backgroundColor: "#000000FF",
        borderRadius: "5px",
    }
}

const itemData = [
    {
        img: require("../assets/screenshots/image.png")
    },
    {
        img: require("../assets/screenshots/image2.png")
    },
    {
        img: require("../assets/screenshots/image3.png")
    },
    {
        img: require("../assets/screenshots/image4.png")
    },
    {
        img: require("../assets/screenshots/image5.png")
    },
    {
        img: require("../assets/screenshots/image6.png")
    },
    {
        img: require("../assets/screenshots/image7.png")
    },
    {
        img: require("../assets/screenshots/image8.png")
    },
    {
        img: require("../assets/screenshots/image9.png")
    },
    {
        img: require("../assets/screenshots/image10.png")
    },
    {
        img: require("../assets/screenshots/image11.png")
    },
    {
        img: require("../assets/screenshots/image12.png")
    }
]

export default AttentionGetter;