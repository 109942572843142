import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {ReactNode} from "react";

const RuleAccordion = (props: {
    title: ReactNode,
    description: ReactNode,
}) => {
    return (
        <Accordion sx={styles.root}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{backgroundColor: "primary.main"}}
            >
                <Typography>{props.title}</Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{backgroundColor: "primary.dark"}}>
                <Typography>
                    {props.description}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

const styles = {
    root: {

        display: "flex",

        margin: "2px",


        flexDirection: "column",
        overflowY: "auto",

        color: "#FFFFFF"
    },
}

export default RuleAccordion