import React from "react";
import AttentionGetter from "../components/AttentionGetter";
import Page from "../meta/Page";

function ServerMap() {
    return (
        <Page title={"Map"}>
            <iframe style={styles.iframe} src="http://map.thefmsmp.com:8123/"></iframe>
        </Page>
    );
}

const styles = {
    iframe: {
        width: "100%",
        height: "100%",
    }
}

export default ServerMap