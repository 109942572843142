import {createTheme, ThemeOptions} from "@material-ui/core";

/*
export const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: "dark",
        primary: {
            main: '#121212',
            light: '#232323',
            dark: '#000000',
            contrastText: '#fefefe',
        },
        secondary: {
            main: '#dedede',
            light: '#cfcfcf',
            dark: '#808080',
            contrastText: '#030303',
        },
        background: {
            default: '#0c0c0c',
            paper: '#1c1c1c',
        },
        text: {
            primary: '#dcdbdb',
        },
        divider: 'rgba(128,128,128,0.2)',
    },
};

 */


export const badOptions: ThemeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#ff009e',
            light: '#00ff30',
            dark: '#ffee36',
            contrastText: '#603800',
        },
        secondary: {
            main: '#00ebff',
            light: '#3ecb00',
        },
        text: {

            primary: 'rgba(185,35,35,0.87)',
            secondary: 'rgba(41,120,123,0.54)',
            disabled: 'rgba(0,0,0,0.94)',
            hint: 'rgba(255,84,84,0.38)',
        },
        background: {
            default: '#8c4600',
            paper: '#f8ff00',
        },
    },
    typography: {
        fontFamily: '"Creepster", "Helvetica", "Arial", sans-serif',
        fontSize: 46,
        fontWeightLight: 100,
    },
};

export const goodOptions: ThemeOptions = {
    palette: {
        type: 'dark',
        primary: {
            main: 'rgba(113,127,226,0.91)',
        },
        secondary: {
            main: '#79bdab',
        },
        background: {
            default: '#000000',
            paper: '#141414',
        },
    },
    typography: {
        fontFamily: '"Source Sans Pro", "Helvetica", "Arial", sans-serif',
        fontSize: 16,
    },
    props: {
        MuiAppBar: {
            color: 'default',
        },
    },
    overrides: {


    }
};





export const darkTheme = createTheme(goodOptions);

