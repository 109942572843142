import {Accordion, AccordionDetails, AccordionSummary, Box, List, Typography} from "@mui/material";
import React from "react";
import Page from "../meta/Page";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RuleAccordion from "../components/RuleAccordion";
import LinkItem from "../components/LinkItem";

const Info = () => {

    return (
        <Page title={"Info"}>

            <Box sx={styles.root}>

                <Typography>The FMSMP</Typography>
                <Typography>mc.thefmsmp.com</Typography>

                <RuleAccordion
                    title=
                        {"Rule I: No Hacked Clients"}
                    description=
                        {
                        <>
                            <p>A hacked client is defined as any client which gives unfair advantages to users that are unavailable to those using the vanilla client</p>
                            <p>These Banned Clients Include, but are not limited to: </p>

                            <List>
                                <LinkItem name={"Sigma Client"      }    link={"https://media.makeameme.org/created/why-did-u-0d3qk9.jpg"  }></LinkItem>
                                <LinkItem name={"Wurst Client"      }    link={"https://media.makeameme.org/created/why-did-u-0d3qk9.jpg"  }></LinkItem>
                                <LinkItem name={"Aristois Client"   }    link={"https://media.makeameme.org/created/why-did-u-0d3qk9.jpg"     }></LinkItem>
                                <LinkItem name={"Meteor Client"     }    link={"https://media.makeameme.org/created/why-did-u-0d3qk9.jpg"   }></LinkItem>
                                <LinkItem name={"Impact Client"     }    link={"https://media.makeameme.org/created/why-did-u-0d3qk9.jpg"   }></LinkItem>
                            </List>
                            <br/>

                            <p>Certain clients have features which are unavailable to those on a vanilla client, but they do not provide a significant enough advantage to warrant being banned</p>
                            <p>These Banned Clients Include, but are not limited to: </p>

                            <List>
                                <LinkItem name={"Badlion Client"   }    link={"https://client.badlion.net/"     }></LinkItem>
                                <LinkItem name={"Lunar Client"     }    link={"https://www.lunarclient.com/download"   }></LinkItem>
                                <LinkItem name={"Feather Client"     }    link={"https://feathermc.com/"   }></LinkItem>
                            </List>

                            <p>Note that if any of these clients have features which violate any other rule, those features are <em>still banned</em> </p>
                        </>}
                />

                <RuleAccordion
                    title=
                        {"Rule II: No X-Ray/Visual Cheats"}
                    description=
                        {
                            <>
                                <p>A visual cheat is defined as any alteration to the player's view that give an unfair advantage to the user</p>
                                <p>These banned cheats include, but are not limited to: </p>
                                <List>
                                    <LinkItem name={"X-Ray"   }    link={"https://www.minecraftforum.net/forums/minecraft-java-edition/discussion/157980-xray-ruins-multiplayer#:~:text=Xray%20is%20infamous%20for%20allowing%20people%20to%20accrue,hiding%20ones%20goods%20on%20an%20anarchy%20server%20impossible"     }></LinkItem>
                                    <LinkItem name={"Player Tracing"     }    link={"https://hypixel.net/threads/pls-tell-me-what-esp-mean-i-asked-it-before-but-no-answer.4079577/"   }></LinkItem>
                                    <LinkItem name={"Free-cam"     }    link={"https://www.reddit.com/r/technicalminecraft/comments/zsfyno/are_freecam_and_xray_cheating/"   }></LinkItem>
                                </List>

                                <br/>

                                <p>Certain visual changes provide advantages which are unavailable to those on a vanilla client, but they do not provide a significant enough advantage to warrant being banned</p>
                                <p>These changes include, but are not limited to: </p>

                                <List>
                                    <LinkItem name={"Full-Bright"   }                       link={"https://www.curseforge.com/minecraft/texture-packs/fullbright"     }></LinkItem>
                                    <LinkItem name={"Most Texture/Resource Packs"     }     link={"https://www.planetminecraft.com/texture-packs/"   }></LinkItem>
                                    <LinkItem name={"Visual GUI Mods"     }                 link={"https://www.fandomspot.com/minecraft-ui-mods/"   }></LinkItem>
                                </List>

                            </>
                        }
                />

                <RuleAccordion
                    title=
                        {"Rule III: No Unfair Minimap"}
                    description=
                        {
                    <>
                        <p>Minimaps are allowed <em>if and only if</em> they lack the ability to detect entities and provide only a bird's eye view</p>

                        <p>Note that if any minimaps have features which violate any other rule, those features are <em>still banned</em> </p>
                    </>
                        }
                />


                <RuleAccordion
                    title=
                        {"Rule IV: No NSFW Content"}
                    description=
                        {
                            <>
                                <p>NSFW (Not Safe For Work) content is defined as any message, image, or structure which depicts excessive vulgarity</p>

                                <p>Content regarded as NSFW includes, but is not limited to: </p>
                                <List>
                                    <LinkItem name={"Sexual Depictions/Messages"   }    link={"https://en.wikipedia.org/wiki/Sexual_content#:~:text=In%20media%20discourse%2C%20sexual%20content%20is%20material%20depicting,as%20flirting%2C%20or%20include%20sexual%20language%20and%20euphemisms."     }></LinkItem>
                                    <LinkItem name={"Excessive Gore"     }    link={"https://www.reddit.com/r/writing/comments/8wm45p/what_is_considered_gore/"   }></LinkItem>
                                    <LinkItem name={"Excessive Profanity"     }    link={"https://i.redd.it/bjpurtktx6v11.png"   }></LinkItem>
                                </List>

                                <p>This policy also applies to skins, usernames, and structures, along with actions and messages</p>



                            </>
                        }
                />
                <RuleAccordion
                    title=
                        {"Rule V: No Harassment"}
                    description=
                        {<>
                            <p>Harassment is defined as any behavior which causes excessive psychological distress to another player</p>

                            <p>Actions which constitute harassment include, but are not limited to: </p>
                            <List>
                                <LinkItem name={"Bullying"   }    link={"https://www.stopbullying.gov/bullying/what-is-bullying"     }></LinkItem>
                                <LinkItem name={"Excessive Greifing"     }    link={"https://www.minecraftforum.net/forums/minecraft-java-edition/discussion/143871-definition-of-griefing"   }></LinkItem>
                                <LinkItem name={"Verbal Abuse"     }    link={"https://www.verywellmind.com/how-to-recognize-verbal-abuse-bullying-4154087"   }></LinkItem>
                            </List>

                            <p>There is of course a line, <em>a very fine line</em>, between teasing, trolling, and harassment </p>
                            <p>Administrative and group consensuses will generaly decide on a case by case basis, but for the most part just use your human judgment. If it feels like you might have gone to far, you have gone to far </p>

                        </>}
                />
                <RuleAccordion
                    title=
                        {"Rule VI: No Discrimination"}
                    description=
                        {<>
                            <p>Discrimination is defined as a purposeful mistreatment of a specific group of people with the intent of causing psychological distress</p>
                            <p>In the context of server rules, an action is only considered discrimination of it is because of a quality a person can not change or influence</p>
                            <p>These qualities include, but are not limited to: </p>
                            <List>
                                <LinkItem name={"Race"}   link={"https://en.wikipedia.org/wiki/Race_(human_categorization)"}></LinkItem>
                                <LinkItem name={"Ethnicity"}   link={"https://en.wikipedia.org/wiki/Ethnicity"}></LinkItem>
                                <LinkItem name={"Sexual Orientation"}   link={"https://en.wikipedia.org/wiki/Sexual_orientation"}></LinkItem>
                                <LinkItem name={"Religion"}   link={"https://en.wikipedia.org/wiki/Religion"}></LinkItem>
                                <LinkItem name={"Physical Characteristics"}   link={"https://en.wikipedia.org/wiki/Body_shaming"}></LinkItem>
                                <LinkItem name={"Physical/Mental Disabilities"}   link={"https://en.wikipedia.org/wiki/Disability"}></LinkItem>

                            </List>

                            <p>Qualities that do not qualify for discrimination include: </p>
                            <List>
                                <LinkItem name={"Political Beliefs"}   link={"https://en.wikipedia.org/wiki/List_of_political_ideologies"}></LinkItem>
                                <LinkItem name={"Personality"}   link={"https://en.wikipedia.org/wiki/Personality"}></LinkItem>
                                <LinkItem name={"In-Game Group/Team affiliation"}   link={"https://en.wikipedia.org/wiki/Team"}></LinkItem>

                            </List>

                            <p>Actions which constitute discrimination include, but are not limited to: </p>
                            <List>
                                <LinkItem name={"Use of offensive slurs/epithets"   }    link={"https://en.wikipedia.org/wiki/Lists_of_pejorative_terms_for_people"     }></LinkItem>
                                <LinkItem name={"Offensive depictions of a particular group of people  "     }    link={"https://en.wikipedia.org/wiki/Stereotype"   }></LinkItem>
                                <LinkItem name={"Targeting or Dismissing a player on the basis of a group they belong to"     }    link={"https://www.apa.org/topics/racism-bias-discrimination/types-stress"   }></LinkItem>
                                <LinkItem name={"Use of Hate Symbols or other offensive imagery"     }    link={"https://www.adl.org/sites/default/files/"   }></LinkItem>
                            </List>


                        </>}
                />

                <RuleAccordion
                    title=
                        {"Rule VII: No Multi-Accounting"}
                    description=
                        {
                    <>
                        <p>Multi-Accounting is defined as the use of multiple accounts for the purpose of circumventing rules/limitations put in place</p>

                        <p>The only exception to this rule is the use of GeyserMC linked account to allow play on Bedrock and Java  </p>

                    </>}
                />
                <RuleAccordion
                    title=
                        {"Rule VIII: No Botting/Autoclicking"}
                    description=
                        {<>
                            <p>Botting is defined as using an external program to execute actions which can only be done by a human</p>

                            <p>Actions which constitute botting include, but are not limited to: </p>
                            <List>
                                <LinkItem name={"Autoclicking"} link={"https://en.wikipedia.org/wiki/Auto_clicker"}></LinkItem>
                                <LinkItem name={"Baritone Botting"}link={"https://www.curseforge.com/minecraft/mc-mods/baritone-1-18-2"}></LinkItem>
                                <LinkItem name={"Macros"} link={"https://www.xbitlabs.com/what-are-macros-in-gaming/"}></LinkItem>
                            </List>

                        </>}
                />
                <RuleAccordion
                    title=
                        {"Rule IX: No Game-Breaking Exploits"}
                    description=
                        {<>
                            <p>A Game Breaking Exploit is defined as any exploit which renders the game unplayable or ruins the general progression of the game</p>

                            <p>Exploits which are considered game-breaking include, but are not limited to: </p>
                            <List>
                                <LinkItem name={"Duping"} link={"https://technical-minecraft.fandom.com/wiki/Item_Duplication"}></LinkItem>
                                <LinkItem name={"Lag Machines"} link={"https://www.minecraftforum.net/forums/minecraft-java-edition/discussion/179938-lag-machines-how-do-i-spot-them-and-stop-them"}></LinkItem>
                                <LinkItem name={"Abuse of Chunk Loaders"} link={"https://www.sportskeeda.com/minecraft/chunk-loaders-minecraft-all-need-know"}></LinkItem>
                            </List>

                        </>}
                />

                <RuleAccordion
                    title=
                        {"Rule X: Have Fun!"}
                    description=
                        {<>
                            <p>The most important rule: This server is meant for fun!</p>
                            <p>All of these rules listed here are general guidelines to enforce this rule</p>
                            <p>Regardless of if what you are doing is "allowed" or not, just consider if what you are doing is making the server more or less fun</p>

                            <p>In other words..</p>

                            <a href="https://youtu.be/rph_1DODXDU">Be excellent to each other, and party On dudes!</a>

                        </>}
                />



            </Box>

        </Page>
    )
}

const styles = {


    root: {
        display: "flex",
        width: "80vw",
        height: "95vh",


        margin: "auto",

        flexDirection: "column",
        overflowY: "scroll",

        color: "#FFFFFF"
    },
}


export default Info