import {Box, Card, CardContent, CardHeader, List, Typography} from "@mui/material";
import {getServerInfo, ServerInfoPacket} from "../../meta/data/ServerAPI";
import {LineChart} from "@mui/x-charts";
import PlayerList from "./PlayerList";
import {ResponsiveContainer} from "recharts";
import {useEffect, useState} from "react";



const ServerStatusFrame = () => {
    const [data, setData] = useState<ServerInfoPacket[]>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const styles = {
        root: {
            display: "flex",
            width: "100vw",
            height: "95vh",


            margin: "auto",

            flexDirection: "row",
            flexWrap: "wrap",
            overflowY: "auto",

        },
        info: {

            //backgroundColor: "background.paper",

            padding: "4px",
            margin: "5px",


            flexGrow: 1,
            flexShrink: 1,

            maxHeight: "50%",

            flexBasis: "400px",




            display:"flex",
            flexDirection: "column",
        },
        cardHeader: {
            //backgroundColor: "primary.dark",
            height: "10%",
            textAlign: "center",

        },
        cardContent: {
            //backgroundColor: "primary.dark",
            flexGrow: 1
        },

        title: {
            fontSize: "1.7em",
            textAlign: "center"
        },

        chart: {
        },

        banner: {
            flexGrow: 8,
            backgroundColor: data ? data[0].status === "online" ? colors.GOOD : colors.HORRIBLE : colors.HORRIBLE
        },
        onlinePlayers: {
            flexGrow:8,
        },

        onlinePlayerGraph: {
            flexGrow:10,
        },

        tps: {
            flexGrow:10,
            backgroundColor: getTPSRating(data ? data[0].tps:0)
        },

        latency: {
            flexGrow:10,
            backgroundColor: getPingRating(data ? data[0].ping:0)
        }




    }

    useEffect(()=> {
        let time = setInterval(() => {
            const getData = async () => {
                try {
                    const serverInfo = await getServerInfo();
                    console.log(serverInfo)
                    setData(serverInfo);
                    setLoading(false);
                } catch (e) {
                    setError(true)
                    setLoading(false)
                    console.log(data)
                }
            }
            getData();
        }, 1000);

        return ()=> {
            clearInterval(time);
        }
    },[])





    if(loading) {
        return (
            <Card variant={"elevation"} sx={Object.assign(styles.banner,styles.info)}>
                <CardHeader sx={styles.cardHeader} title={<Typography sx={styles.title}>{"Loading Information...."}</Typography>}></CardHeader>

            </Card>
        )
    }else if(error || data === undefined) {
        return (
            <Card variant={"elevation"} sx={Object.assign(styles.banner,styles.info)}>
                <CardHeader sx={styles.cardHeader} title={<Typography sx={styles.title}>{"ERR Loading Information"}</Typography>}></CardHeader>

            </Card>
        )
    }else {

        return (
            <Box sx={styles.root}>
                <Card variant={"elevation"} sx={Object.assign(styles.banner,styles.info)}>
                    <CardHeader sx={styles.cardHeader} title={<Typography sx={styles.title}>{"Status: " + data[0].status}</Typography>}></CardHeader>
                    <CardContent>
                        <Typography>{"Status: " + data[0].status}</Typography>
                        <Typography>{"Current Uptime: " + data[0].uptime}</Typography>
                    </CardContent>
                </Card>

                <Card variant={"elevation"} sx={Object.assign(styles.onlinePlayers,styles.info)}>
                    <CardHeader sx={styles.cardHeader} title={<Typography sx={styles.title}>Current Players</Typography>}>
                    </CardHeader>
                    <CardContent sx={styles.cardContent}>
                        <PlayerList players={data[0].players}></PlayerList>
                    </CardContent>
                </Card>

                <Card variant={"elevation"} sx={Object.assign(styles.onlinePlayerGraph,styles.info)}>
                    <CardHeader sx={styles.cardHeader} title={<Typography sx={styles.title}>Players Online: {data[0].playersOnline}</Typography>}>
                    </CardHeader>
                    <CardContent sx={styles.cardContent}>
                        <ResponsiveContainer width={"100%"} height={"100%"}>
                            <LineChart
                                series={[{ dataKey: 'playersOnline'}]}
                                dataset={data as any}
                                xAxis={[{scaleType:"point",dataKey:'timestamp'}]}
                                yAxis={[{min:0,max:Math.max(10,data[0].playersOnline)}]}
                            />
                        </ResponsiveContainer>
                    </CardContent>
                </Card>



                <Card variant={"elevation"} sx={Object.assign(styles.tps,styles.info)}>
                    <CardHeader sx={styles.cardHeader} title={<Typography sx={styles.title}>Average TPS: {data[0].tps.toFixed(2)}</Typography>}>
                    </CardHeader>
                    <CardContent sx={styles.cardContent}>
                        <ResponsiveContainer width={"100%"} height={"100%"}>
                            <LineChart
                                series={[{ dataKey: 'tps'}]}
                                dataset={data as any}
                                xAxis={[{scaleType:"point",dataKey:'timestamp'}]}
                                yAxis={[{min:0,max:20}]}
                            />
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
                <Card variant={"elevation"} sx={Object.assign(styles.info,styles.latency)}>
                    <CardHeader sx={styles.cardHeader} title={<Typography sx={styles.title}>Latency: {data[0].ping}</Typography>}>
                    </CardHeader>
                    <CardContent sx={styles.cardContent}>
                        <ResponsiveContainer width={"100%"} height={"100%"}>
                            <LineChart
                                series={[{ dataKey: 'ping'}]}
                                dataset={data as any}
                                xAxis={[{scaleType:"point",dataKey:'timestamp'}]}
                                yAxis={[{min:0,max:Math.max(100,data[0].ping)}]}
                            />
                        </ResponsiveContainer>
                    </CardContent>
                </Card>


            </Box>
        )
    }





}

const colors = {
    GREAT: "#33DD3333",
    GOOD: "#88DD3333",
    ACCEPTABLE: "#FFDD3333",
    WARNING: "#FF773333",
    HORRIBLE: "#DD000033"
}

const getPingRating = (ping:number) => {
    if(ping < 20) {
        return colors.GREAT
    }
    if(ping < 50) {
        return colors.GOOD
    }
    if(ping < 100) {
        return colors.ACCEPTABLE
    }
    if(ping < 300) {
        return colors.WARNING
    }
    return colors.HORRIBLE
}
const getTPSRating = (tps: number) => {
    if(tps > 19.5) {
        return colors.GREAT
    }
    if(tps > 19) {
        return colors.GOOD
    }
    if(tps > 17) {
        return colors.ACCEPTABLE
    }
    if(tps > 14) {
        return colors.WARNING
    }
    return colors.HORRIBLE
}



/*
const fetchServerInformation = async () => {
    let res = await fetch("https://api.mcsrvstat.us/3/testmc.thefmsmp.com")
    return res.json();
}

const [serverInfo,setServerInfo] = useState<any[]>()
const loadServer = async () => {
    const res  = await fetch("https://api.mcsrvstat.us/3/testmc.thefmsmp.com");
    return await res.json();
}

useEffect(() => {
    loadServer().then(info => {
        console.log("Server information")
        console.log(info)
        setServerInfo(info)

    })
},[])

 */

export default ServerStatusFrame;