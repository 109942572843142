import {Box, Card, CardContent, CardHeader, Typography} from "@mui/material";
import Caden from "../assets/staff_images/caden.jpg";
import React from "react";

const StaffCard = (props:
                       {
                           name: string,
                           roles: string,
                           bio: string,
                           img: string
                       }
                       ) => {


    return(
        <Card sx={Object.assign({backgroundImage:`url(${props.img})`},styles.staffCard) }>
            <CardHeader sx={styles.staffCardHeader}
                        title={<Typography variant={"h4"} >{props.name}</Typography>}
                        subheader={<Typography variant={"caption"} >{props.roles}</Typography>}

            >
            </CardHeader>
            <CardContent sx={styles.staffCardDesc}>
                <Box sx={styles.descriptorBox}>
                    <Typography variant={"body1"} >{props.bio}</Typography>
                </Box>
            </CardContent>
        </Card>
    )
}

const styles = {
    staffCard: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "300px",
        flexGrow: 1,
        margin: "10px",
        backgroundSize: "cover",
        textShadow: "2px 2px 2px #000000",
    },

    staffCardDesc: {
        backgroundColor: "#000000AA",
        flex:1,
        display: "flex",
        alignItems: "flex-end"
    },


    staffCardHeader: {
        backgroundColor: "#000000AA",
    },
    descriptorBox: {
        height: "50%",
        overflowY: "auto"
    }
}

export default StaffCard