import {Box, Button, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import React, {ReactNode, useState} from "react"
import {BrowserRouter, useNavigate, useNavigation} from "react-router-dom";


interface Props {
    text: string,
    link: string,
    icon: ReactNode,
    setFunction(arg: boolean): any
}

const NavButton = (props: React.PropsWithChildren<Props>) => {

    const [isHovered, setHovered] = useState(false);


    const styles = {
        text: {
            //color: '#DCDC55',
            fontFamily: "Minecraft-Bold",
            fontSize: "32px",

        },
        root: {
            //backgroundColor: isHovered ? "#FFFFFF22" : "#00000022"

        },

        icon: {
            //color: '#DCDC55',
        }
    }


    const navigate = useNavigate();
    function navigateTo() {

        navigate(props.link)
        props.setFunction(false)
    }
    return(
        <>
            <ListItem sx={styles.root}disablePadding onMouseEnter={() => {setHovered(true)}} onMouseLeave={() => {setHovered(false)}}>
                <ListItemButton onClick={navigateTo}>
                    <ListItemIcon sx={styles.icon}>
                        {props.icon}
                    </ListItemIcon>
                    <ListItemText style={styles.text} primary={props.text} sx={{fontFamily:"Minecraft"}} />
                </ListItemButton>
            </ListItem>
        </>

    )


}




export default NavButton