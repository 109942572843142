import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/Home';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Plugins from "./pages/Plugins";
import ServerStatus from "./pages/ServerStatus";
import ServerMap from "./pages/ServerMap";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {darkTheme} from "./theme/MainTheme";
import About from "./pages/About";
import Info from "./pages/Info";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(([
    {
        index: true,
        path: "/",
        element: Home()
    },
    {
        path: "/map",
        element: ServerMap()
    },
    {
        path: "/plugins",
        element: Plugins()
    },
    {
        path: "/status",
        element: ServerStatus()
    },
    {
        path: "/about",
        element: About()
    },
    {
        path: "/info",
        element: Info()
    }
]))
root.render(

  <React.StrictMode>
      <CssBaseline enableColorScheme>
          <ThemeProvider theme={darkTheme}>
              <RouterProvider router={router} />
          </ThemeProvider>
      </CssBaseline>
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
