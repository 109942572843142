import {ListItem, ListItemButton, Typography} from "@mui/material";

const LinkItem = (props: {name:string,link:string}) => {
    return (
        <ListItem sx={{backgroundColor: "primary.dark"}}>
            <ListItemButton onClick={() => {window.location.href = props.link}}>
                <Typography>{props.name}</Typography>
            </ListItemButton>
        </ListItem>
    )
}



export default LinkItem