import React, {useEffect} from "react";
import AttentionGetter from "../components/AttentionGetter";
import Page from "../meta/Page";
import {getPluginInfo} from "../meta/data/ServerAPI";
import PluginPanel from "../components/PluginPanel";

function Plugins() {




    return (
        <Page title={"Plugins"}>
            <PluginPanel></PluginPanel>
        </Page>
    );
}

export default Plugins