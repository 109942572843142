export const ServerAPI = () => {

}

const API_ROOT = "http://thefmsmp.com:8080/api/"

export interface ServerInfoPacket {
    status:string;
    timestamp:string;
    players: Player[];
    playersOnline:number;
    tps:number;
    ping:number;
    uptime: string;
}

export interface Player {
    name: string
    icon: string
}

export interface Plugin {
    name: string;
    desc: string;
    URL: string
    version: string;
    authors: string;
}

interface ServerInfoPacket2 {
    status: string
    uptime: string;
    restart: string;
    players: string[];
    data: any[]
}

interface ServerDataSnapshot {
    timestamp: string
    playersOnline: number
    tps: number
    latency: number
}

export async function getServerInfo():Promise<ServerInfoPacket[]> {
    return await fetch(API_ROOT+"serverDetails").then((data) => {
        return data.json() as Promise<ServerInfoPacket[]>
    });
}

export async function getPluginInfo() {
    return await fetch(API_ROOT+"plugins").then(
        (data) => {
            return data.json() as Promise<Plugin[]>
        }
    )
}

/*
export function getTestServerInfoPacket(): ServerInfoPacket {

    return {
        status: "Online",
        uptime: "03:32:56",
        restart: "11:00PM",
        players: ["Lzan","Multipedbeatle","Wyit"],
        data: [
            {
                timestamp: "10:00PM",
                playersOnline: 3,
                tps: 19,
                latency: 5
            },
            {
                timestamp: "09:55PM",
                playersOnline: 3,
                tps: 19,
                latency: 6
            },
            {
                timestamp: "09:50PM",
                playersOnline: 4,
                tps: 18,
                latency: 4
            },
            {
                timestamp: "09:45PM",
                playersOnline: 4,
                tps: 17,
                latency: 7
            },
            {
                timestamp: "09:40PM",
                playersOnline: 6,
                tps: 16,
                latency: 6
            },
            {
                timestamp: "09:35PM",
                playersOnline: 6,
                tps: 17,
                latency: 5
            },
            {
                timestamp: "09:30PM",
                playersOnline: 5,
                tps: 17,
                latency:3
            },
            {
                timestamp: "09:25PM",
                playersOnline: 5,
                tps: 17,
                latency: 2
            },
            {
                timestamp: "09:20PM",
                playersOnline: 5,
                tps: 17,
                latency: 4
            },
            {
                timestamp: "09:15PM",
                playersOnline: 8,
                tps: 16,
                latency: 6
            },
            {
                timestamp: "09:10PM",
                playersOnline: 8,
                tps: 17,
                latency: 5
            },
            {
                timestamp: "09:05PM",
                playersOnline: 8,
                tps: 17,
                latency: 3
            },
            {
                timestamp: "09:00PM",
                playersOnline: 7,
                tps: 17,
                latency: 5
            },

        ]
    }

 */
